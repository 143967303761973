import React from 'react';
import parse from 'react-html-parser';
import { graphql } from 'gatsby';
import moment from 'moment';
import readingTime from 'reading-time';

import Img from 'gatsby-image';

import Layout from 'components/Layout';
import Helmet from 'components/Helmet';
import styles from './styles.module.scss';
import { SlideUpThenDown } from '../../components/Transition';
import Link from '../../components/Link';

function PostTile({ uid, postData }) {
  const { title, featured_image } = postData;
  return (
    <Link to={`/blog/${uid}`} className={styles.postTile} key={`recent-post-${uid}`}>
      <Img alt={'title'} className={styles.postImage} fluid={featured_image.localFile.childImageSharp.fluid} />
      <div className={styles.postTileTitle}>{title.text}</div>
    </Link>
  );
}

function getFullBodyText(body) {
  const arrayOfWords =
    (body &&
      body.reduce &&
      body.reduce((acc, slice) => {
        const words = slice && slice.primary && (slice.primary.quote || slice.primary.text),
          text = words && words.text;
        text && acc.push(text);
        return acc;
      }, [])) ||
    [];

  return arrayOfWords.join(' ');
}

function getReadingTimeStr(body) {
  const readingTimeObj = readingTime(getFullBodyText(body));
  return readingTimeObj && readingTimeObj.text;
}

function parseBody(body) {
  let isFirstText = true;

  return body.map((slice, i) => {
    const { slice_type, primary, internal } = slice,
      { quote, text, image, caption } = primary,
      key = `slice-${internal.contentDigest}`;

    switch (slice_type) {
      case 'quote':
        return parseQuote({ quote, key });
      case 'text':
        const parsedText = parseText({ text, key, isFirstText });
        isFirstText = false;
        return parsedText;
      case 'image':
        return parseImage({ image, caption, key });
      default:
        return null;
    }
  });
}

function parseQuote({ quote, key }) {
  return (
    <div key={key} className={styles.quote}>
      {parse(quote.html)}
    </div>
  );
}

function parseImage({ key, image, caption }) {
  return (
    <div key={key} className={styles.embeddedPic}>
      <Img fluid={image.localFile.childImageSharp.fluid} />
      <span className={styles.embeddedPicCaption}>{parse(caption.html)}</span>
    </div>
  );
}

function parseText({ text, key, isFirstText }) {
  const classNames = [styles.postText];
  isFirstText && classNames.push(styles.dropcap);
  return (
    <div key={key} className={classNames.join(' ')}>
      {parse(text.html)}
    </div>
  );
}

const Index = ({ data: { prismicPost } }) => {
  const { data } = prismicPost,
    { title, body, release_date, featured_image, related_posts } = data,
    readingTimeStr = getReadingTimeStr(body),
    relatedPosts = related_posts
      .map(({ related_post }) => related_post)
      .filter(({ document }) => !!document[0].data.featured_image.localFile)
      .slice(0, 4);

  return (
    <Layout>
      {/* TODO: pass featured image as share image */}
      <Helmet title={title.text} type={'article'} />
      <SlideUpThenDown>
        <div className={styles.postRoot}>
          <div className={styles.column}>
            <div className={styles.postHead}>
              <div className={styles.postTitle}>{parse(title.html)}</div>
              <div className={styles.postDate}>{moment(release_date).format('MMM D, YYYY')}</div>
              {readingTimeStr && <div className={styles.readingTime}>{readingTimeStr}</div>}
              {featured_image && featured_image.localFile && (
                <div className={styles.featuredImage}>
                  <Img fluid={featured_image.localFile.childImageSharp.fluid} alt={featured_image.alt} />
                </div>
              )}
            </div>
            <div className={styles.postBody}>{parseBody(body)}</div>
          </div>
          {related_posts && related_posts.length > 0 && (
            <div className={styles.relatedPosts}>
              <div className={styles.relatedPostsHeader}>More Posts</div>
              {relatedPosts.map(({ uid, document }) => (
                <PostTile uid={uid} postData={document[0].data} />
              ))}
            </div>
          )}
        </div>
      </SlideUpThenDown>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          html
          text
        }
        release_date
        related_posts {
          related_post {
            uid
            document {
              data {
                release_date
                title {
                  text
                }
                featured_image {
                  alt
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                lead_in {
                  html
                  text
                }
              }
            }
          }
        }
        featured_image {
          alt
          copyright
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            primary {
              text {
                html
                text
              }
            }
            internal {
              contentDigest
            }
          }
          ... on PrismicPostBodyQuote {
            slice_type
            primary {
              quote {
                html
                text
              }
            }
            internal {
              contentDigest
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              caption {
                html
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            internal {
              contentDigest
            }
          }
        }
      }
    }
  }
`;
